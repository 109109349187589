<script lang="ts">
  import type { HTMLInputAttributes } from 'svelte/elements'

  interface Props {
    value?: string
    focus?: boolean
    class?: string | undefined
    defaultClasses?: string[]
    action?: Action
  }

  let {
    value = $bindable(''),
    focus = false,
    class: cssClass = '',
    defaultClasses = ['input', 'flex', 'min-w-96', 'w-fit', 'px-2', 'py-1', 'my-0', 'box-border'],
    action = { fn: () => {}, args: undefined },
    ...restProps
  }: Props & HTMLInputAttributes = $props()

  interface Action<Args = unknown> {
    fn: (node: HTMLElement, args: Args) => void
    args: Args
  }

  const init = (input: HTMLInputElement) => {
    if (focus) {
      input.focus()
    }
  }

  const actionFn = <Args,>(node: HTMLElement, args: Args) => {
    return action.fn(node, args)
  }
</script>

<input
  {...restProps}
  bind:value
  use:init
  class="{cssClass} {defaultClasses.join(' ')}"
  use:actionFn={action.args}
/>
